import React from 'react';
import DataNotFound from '../../../../components/DataNotFound';

function MisTurnos() {
    return (
        <div className='in'>
               <DataNotFound text="mis turnos" />
        </div>
    )
}

export default MisTurnos;
